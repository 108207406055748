import User from '../../Models/User';
import axiosInstance from '../../Utils/axios_manager';

export default class MessageService {
  
    constructor( ) {
    }

    public postMessage =  (user: User) => {
        return axiosInstance.post('/client/add', User.toJson(user));
    }

    
    public getMessages =  async (id: Number) => {
        return await axiosInstance.get('/disc/list'  + `?idAgent=${id.toString()}` );
    }

    public getClient =  async (id: Number) => {
        return await axiosInstance.get('/client/details' + `?id=${id.toString()}`);
    }

    public putMessage =  (user: User) => {
        return axiosInstance.post('/client/update', User.toJson(user));
    }

    public uploadFile = (file: any) => {
        const body = new FormData();
        body.append('file', file); 

        return axiosInstance.post('/msg/file/upload', body);
    }

    public createDiscussion = (data: Record<string, any>) => {
        return axiosInstance.post('/disc/create', data);
    }

    public mailSend = (data: Record<string, any>) => {
        return axiosInstance.post('/mail/send', data);
    }

    public getChatDetail =  async (id: Number) => {
        return await axiosInstance.get('/disc/detail' + `?idDiscussion=${id.toString()}`);
    }
  

}