import { FC, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import ContributionService from './service';

//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate
} from "react-router-dom";

// React - Bootstrap
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Formik, Field, Form } from 'formik'
import * as yup from 'yup';

//Redux 
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
 

//Utils
import axiosInstance from '../../Utils/axios_manager';

const GiveContribution: FC = () => {  
    let idToModInit: any = null;
    const { t } = useTranslation();
    const [ contributions, setContributions ] = useState( [] );
    const [key, setKey] = useState('all');
    const [ filteredAgents, setFilteredAgents ] = useState( [] );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const contributionService = new ContributionService();

    const [keys, setKeys] = useState([
        {
            key: 'all',
            label: `${t('Tout')}` 
        },
        {
            key: 'fulltime',
            label: `${t('Temps plein')}`
        },
        {
            key: 'season',
            label: `${t('Saisonières')}`
        },
        {
            key: 'contract',
            label: `${t('Contractuels')}`
        },
        {
            key: 'stage',
            label: `${t('Stagiaires')}`
        }
    ]);

    const navigate = useNavigate();

    let editMember = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/member/edit/'+id.toString());
        setLoading(false);
    }

    const getContributions = () => {
        setLoading(true);
        contributionService.getContributions().then(async function (response: any) {
            console.log(response);
            setContributions(response.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

 

    useEffect( function() { 
        getContributions();
    }, []);

    const columns = [
        {
            name: `${t('Montant')}`,
            selector: (row: any) => row.amount,
        }, 
        {
            name: `${t("Date de paiement")}`,
            selector: (row: any) => <Moment format="DD/MM/YYYY hh:mm:ss">
                                        {row.created_at}
                                    </Moment>
        } 
    ];
    
    const reload = () => {
        getContributions();
    }
    

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                        <div className="btn-list">
                                    
                                            <Link type="button" to="/cotisation"
                                                className="btn waves-effect waves-light btn-danger">
                                                { t('Retour') }    
                                            </Link>
                                        </div>
                                        
                                        <br />
                                        <br />
                                        <div className='row'>
                                            <div className='col-md-2'></div>
                                            <div className='col-md-8'> 
                                            <p className='text-center'>Le montant à cotiser doit être entré en équivalence dollars</p>
                                        <Formik
                                            initialValues={{
                                                amount: null
                                            }}
                                            validationSchema={
                                                yup.object().shape({
                                                    amount: yup
                                                        .number() 
                                                        .required(
                                                            `${t('Ce champ est obligatoire')}`
                                                        )
                                                        .test('positiveChecker', "Amount must be superior to 0", async value => (value ?? 0) > 0 
                                                        ),
                                                })
                                            }
                                            onSubmit={async (
                                                values,
                                                actions
                                            ) => {
                                                console.log(values);
                                                console.log(actions);
                                                 
                                            }}
                                            >
                                            {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                            <Form>
                                            <div className="row"> 
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label className="text-dark" htmlFor="password">
                                                            {
                                                                t('Montant à déposer')
                                                            }
                                                        </label>
                                                        <div className='input-group'>
                                                            <input  className={`form-control ${ errors.amount && touched.amount ? "is-invalid":""}`}
                                                                id="amount"
                                                                type='number'
                                                                name="amount"
                                                                placeholder={ `${t('Montant à déposer')}` }
                                                                onChange={handleChange('amount')}
                                                                onBlur={handleBlur('amount')}
                                                                value={values.amount ?? ''}
                                                            />
                                                            <span className="input-group-append">
                                                                <button onClick={event => { }} className="btn btn-outline-primary border-left-0 border" type="button">
                                                                    $ 
                                                                </button>
                                                            </span>

                                                            { errors.amount && touched.amount && errors.amount && 
                                                        <div id="validationServer05Feedback" className="invalid-feedback">
                                                        { errors.amount && touched.amount && errors.amount }
                                                        </div> }
                                                        </div>
                                                    
                                                        
                                                        
                                                        
                                                    </div>
                                                </div>     
                                                <div className="col-lg-12 text-center">
                                                    <button type="submit" 
                                                        // loading={loading}
                                                        // disabled={!isValid} 
                                                        disabled={!dirty}
                                                        // onClick={event =>  handleSubmit}
                                                        className="btn btn-block btn-primary btn-connexion" >
                                                        {
                                                            t('Payer')
                                                        }
                                                        {
                                                            loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        }
                                                    </button>
                                                </div>
                                                {/* <div className="col-lg-12 text-center mt-5">
                                                    Don't have an account? <a href="#" className="text-danger">Sign Up</a>
                                                </div> */}
                                            </div>
                                            </Form>
                                        )}
                                    </Formik>
                                    </div>
                                        </div>
                                                        
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default GiveContribution