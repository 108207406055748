import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../Hooks/customSelector';

import { RootState } from '../Redux/store';

//Props interface
interface AlertProps {
    title: string;
    message:  string;
    action: React.MouseEventHandler<HTMLButtonElement>;
    actionText?: string;
    type?: 'success' | 'error';
    cancelText?: string;
    cancelAction?: React.MouseEventHandler<HTMLButtonElement>;
}
  
const Alert: FC<{alert: AlertProps}> = ({ alert }) => {

    const { t } = useTranslation();

    if (alert.type == null || alert.type == undefined) {
        alert.type = 'success';
    }

    useEffect(
        () => {
            console.log("Alert is called");
            console.log(alert);
        }, []
    )
 
    return (   
            <div id={` ${ alert.type == 'success' ? 'success-alert-modal' : 'danger-alert-modal' } `}
             className={``}  tabIndex={-1} role="dialog"
                aria-hidden="true" style={{ zIndex: 99999999 }}>
                <div className="modal-dialog modal-sm">
                    <div className={` modal-content modal-filled ${ alert.type == 'success' ? 'bg-success' : 'bg-danger' } `}
                    >
                        <div className="modal-body p-4">
                            <div className="text-center">
                                <i className="dripicons-checkmark h1"></i>
                                <h4 className="mt-2">
                                { t(t('alertTitle', { alertTitleText: alert.title} ) ?? '') }
                                </h4>
                                <p className="mt-3"> 
                                    { t(t('alertMessage', { alertMessageText: alert.message } ) ?? '') }
                                </p>

                                <div className="btn-list">
                                    {
                                        alert.actionText != null && alert.actionText != undefined ? 
                                        <button type="button" onClick={alert.action}  className="btn btn-light my-2"
                                            data-dismiss="modal">
                                                { t(t('alertOk', { alertOkText: alert.actionText } ) ?? '') } 
                                        </button> : <></>
                                    }
                                    {
                                        alert.cancelText != null && alert.cancelText != undefined ? 
                                        <button type="button" onClick={alert.cancelAction}  className="btn btn-light my-2"
                                            data-dismiss="modal">
                                                { t(t('alertCancel', { alertCancelText: alert.cancelText } ) ?? '') }  
                                        </button> : <></>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        );
}
          
export default Alert;