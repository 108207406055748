// let _serverUrl = "localhost/aaic/ADMIN";
let _serverUrl = "api.kekeliedu.net/AAIC";
// "lapotnewapi.nogdevhouse.com";
// "localbackend.nogdevhouse.com";

// let apiProtocol = "http:";

// if (apiProtocol === undefined) {
//   apiProtocol = window.location.protocol;
// } 

let baseApiPath = "https://" + _serverUrl + "/CLIENT";

export const api_url = baseApiPath;
export const api_media_url = "https://" + _serverUrl + "/medias";
