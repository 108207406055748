import User from '../../Models/User';
import Member from '../../Models/Member';
import axiosInstance from '../../Utils/axios_manager';

export default class ContributionService {
  
    constructor() {  }

    public getContributions =  async () => {
        return await axiosInstance.get('/get_contributions.php');
    }

}