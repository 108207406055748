import { FC, useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import * as yup from 'yup';

//Redux 
import { useAppDispatch, useAppSelector } from '../Hooks/customSelector';
import { getUser, setUser } from '../Redux/Reducers/userReducer'

//Utils
import axiosInstance from '../Utils/axios_manager';
import * as Utils from "../Utils/index"; 
import User from '../Models/User';
import { UserState } from '../Redux/States/states';
import { RootState } from '../Redux/store';

// the hook
import { useTranslation } from 'react-i18next';
import { setLang } from '../Redux/Reducers/langReducer';
import Admin from '../Models/Admin';

interface Values {
    email: string; 
    password: string;
  }

const Login: FC = () => {          
    const user = useAppSelector((state: RootState) => state.users.user );
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);

    const lang = useAppSelector((state: RootState) => state.lang.lang );

    const { t, i18n } = useTranslation();

    const [ passwordInputType, setPasswordInputType ] = useState('password');
    const [ passwordInputIcon, setPasswordInputIcon ] = useState('eye');

    useEffect(() => {
        console.log("User change state");
        console.log(user);
    }, [user]);

    return (
        <div  style={{ backgroundColor: "#edd663",
        height: "100%", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}> 
        
            <div className="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative"
            >
        {/* <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <select value={lang} onChange={(event) => { 
                                    const lang = event.target.value; 
                                    i18n.changeLanguage(lang).then(
                                        (res) => {
                                            dispatch( setLang(lang)  ); 
                                        }
                                    );
                                  }}>
                <option value="fr">FR</option>
                <option value="en">EN</option>
            </select>
        </div> */}
        <div className="auth-box row">
            <div className="col-lg-7 col-md-5 modal-bg-img" style={{ backgroundImage: "url(assets/images/logo.jpg)" }}>
            </div>
            <div className="col-lg-5 col-md-7 bg-white">
                <div className="p-3">
                    <div className="text-center">
                        <img style={{ width: "70px", height: "70px" }} src="assets/images/logo.jpg" alt="wrapkit" />
                    </div>
                    {/* <h2 className="mt-3 text-center">Connexion</h2> */}
                    {/* <p className="text-center">
                        {
                            t('Connecte toi avec tes identifiants')
                        }
                    </p> */}
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        validationSchema={
                            yup.object().shape({
                                email: yup
                                    .string()
                                    .email(`${t('Email invalide')}`)
                                    .required(
                                        `${t('Ce champ est obligatoire')}`
                                    ),
                                password: yup
                                    .string()
                                    .required(`${t('Ce champ est obligatoire')}`)
                            })
                        }
                        onSubmit={async (
                            values: Values,
                            actions
                          ) => {
                            console.log(values);
                            console.log(actions);
                            setLoading(true);
                            await axiosInstance.post('/login.php', {
                                email: values.email,
                                password: values.password
                              })
                              .then(async function (response: any) {

                                console.log(response);

                                dispatch( setUser( Admin.fromJson(response['data'] ) ) );

                                window.localStorage.setItem(
                                    'app_user',
                                    JSON.stringify(response['data'])
                                );

                                setLoading(false);

                                console.log( user );

                                window.location.href = "/";

                              })
                              .catch(function (error: any) {
                                console.log(error);
                                setLoading(false);
                              });
                          }}
                        >
                        {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="text-dark" htmlFor="pseudo">
                                        {
                                            t("E-mail")
                                        }
                                    </label>
                                    
                                    <input className={`form-control ${ errors.email && touched.email ? "is-invalid":""}`}
                                        id="email"
                                        type="email"
                                        name="email"
                                        placeholder={ `${t("E-mail")}` }
                                        onChange={handleChange('email')}
                                        onBlur={handleBlur('email')}
                                        value={values.email}
                                    />
                                    
                                    { errors.email && touched.email && errors.email && 
                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                        { errors.email && touched.email && errors.email }
                                    </div> }
                                    
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <label className="text-dark" htmlFor="password">
                                        {
                                            t('Mot de passe')
                                        }
                                    </label>
                                    <div className='input-group'>
                                        <input  className={`form-control ${ errors.password && touched.password ? "is-invalid":""}`}
                                            id="password"
                                            type={ passwordInputType }
                                            name="password"
                                            placeholder={ `${t('Mot de passe')}` }
                                            onChange={handleChange('password')}
                                            onBlur={handleBlur('password')}
                                            value={values.password}
                                        />
                                        <span className="input-group-append">
                                            <button onClick={event => {
                                                setPasswordInputType( passwordInputType === 'password' ? 'text' : 'password' );
                                                setPasswordInputIcon( passwordInputIcon === 'eye' ? "eye-slash" : "eye" );
                                            }} className="btn btn-outline-primary border-left-0 border" type="button">
                                                    <i className={`fa fa-${passwordInputIcon}`}></i>
                                            </button>
                                        </span>

                                        { errors.password && touched.password && errors.password && 
                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                    { errors.password && touched.password && errors.password }
                                    </div> }
                                    </div>
                                  
                                    
                                    
                                    
                                </div>
                            </div>  
                            <div className="col-lg-12">
                                <div className="form-group">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1"
                                            value="option1" />
                                        <label className="form-check-label" htmlFor="inlineCheckbox1">
                                            {
                                                t('Se souvenir de moi')
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>   
                            <div className="col-lg-12 text-center">
                                <button type="submit" 
                                     // loading={loading}
                                     // disabled={!isValid} 
                                     disabled={!dirty}
                                    // onClick={event =>  handleSubmit}
                                    className="btn btn-block btn-primary btn-connexion" >
                                    {
                                        t('Connexion')
                                    }
                                    {
                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                </button>
                            </div>
                            {/* <div className="col-lg-12 text-center mt-5">
                                Don't have an account? <a href="#" className="text-danger">Sign Up</a>
                            </div> */}
                        </div>
                        </Form>
                    )}
                </Formik>
                </div>
                <br />  
                <p className='text-center'>
                    <a href='https://aaic-global.org/' >
           Revenir au site principal de AAIC GLOBAL
        </a>
                    </p>
            </div>
        </div> 
    </div> 
                    
    </div>
    );
}
    
export default Login