import axiosInstance from '../../Utils/axios_manager';

export default class NotificationService {
  
    constructor( ) {
    }

    public getNotifications =  async (id: Number) => {
        return await axiosInstance.get('/notifications/unreaded' + `?idAgent=${id.toString()}`);
    }

}