import './style.css';
import { FC, useEffect, useState, useRef } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb'; 

//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate,
    useParams
} from "react-router-dom";

// React - Bootstrap
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup';

//Utils
import axiosInstance from '../../Utils/axios_manager';

//Redux 
import { useAppDispatch, useAppSelector } from '../../Hooks/customSelector';
import { getUser, setUser } from '../../Redux/Reducers/userReducer';
import Admin from '../../Models/Admin';


//Alert
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Alert from '../../Components/Alert';
import ParameterService from './service';

import * as GLOBAL from "../../Utils"
import Member from '../../Models/Member';

interface passwordFormProps {
    old_password: string;
    password: string;
    passwordConfirmation: string;
}


const Parameter: FC = () => {  

    const { defaultTab } = useParams();
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();

    const formRef = useRef<FormikProps< passwordFormProps >>(null);

    let [ loading, setLoading ] = useState(false); 

    const [oldPasswordInputType, setOldPasswordInputType ] = useState('password');
    const [ oldPasswordInputIcon, setOldPasswordInputIcon ] = useState('eye');

    const [newPasswordInputType, setNewPasswordInputType ] = useState('password');
    const [ newPasswordInputIcon, setNewPasswordInputIcon ] = useState('eye');

    const [newPasswordConfirmInputType, setNewPasswordConfirmInputType ] = useState('password');
    const [ newPasswordConfirmInputIcon, setNewPasswordConfirmInputIcon ] = useState('eye');

    let [image, setImage] = useState('');


    let [ countriesLoading, setCountriesLoading ] = useState(false);

    let [ countries, setCountries ] = useState([]);

    const country_phone_code_init: string = '';


    const readThis = (inputValue: any, filetype: string): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            switch (filetype) {
                case 'profile':
                    setImage( lecteur.result as string );
                    break;
            
                default:
                    setImage( lecteur.result as string );
                    break;
            }
            
            
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);

    }

    const changeListener = (event: any, filetype: string = 'profile')  => {
        readThis(event.target, filetype);
    }
 
    const getCountries = () => {
        setCountriesLoading(true);
        parameterService.getCountries().then(async function (response: any) {
            console.log(response);
            setCountries(response.data.countries);
            setCountriesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setCountriesLoading(false); 
        });
    }

    const [
        countryPhoneCode, setCountryPhoneCode
    ] = useState(country_phone_code_init);

    // Services
    const parameterService = new ParameterService();

    const memberInit: null | any = null;
    let [ member, setMember ] = useState( memberInit );

    let [ loadMember, setLoadMember ] = useState(false);
    
    let [
        activeKey, setActiveKey
    ] = useState('security');

    const getUser = () => {
        setLoadMember(true);
        parameterService.getUser().then(async function (response: any) {
            console.log(response); 
            setMember((user: any) => response.data.user);
            setImage( GLOBAL.api_media_url + "/membres/" + response.data.user?.photo ?? '');
            setLoadMember(false);
        })
          .catch(function (error: any) {
            console.log(error); 
        });
    }

    useEffect(
        () => {
            getUser();
            getCountries();
            if (defaultTab !== null && defaultTab !== '') {

                console.log(defaultTab);

                setActiveKey(() => (defaultTab ?? 'security'));

            }
        }, []
    );

    const handleSelect = (key: any) => { 
        setActiveKey(key);
    }


    return (
        <> 
                <Breadcrumb />
 
                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="btn-list">
                                         
                                    </div>
                                    
                                    <br />
                                    <br />

                                    <Tabs style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }} 
                                    onSelect={handleSelect}
                                     defaultActiveKey='security' activeKey={activeKey} id="uncontrolled-tab-example">
                                        <Tab eventKey="security" title="Séurité">
                                            <br/>
                                            <Formik
                                                initialValues={ 
                                                    {   old_password:'',
                                                        password:'',
                                                        passwordConfirmation: ''
                                                }}
                                                validationSchema={
                                                    yup.object().shape({
                                                        old_password: yup
                                                            .string()
                                                            .required(`${'Ce champ est obligatoire'}`),
                                                        password: yup
                                                            .string()
                                                            .required(`${'Ce champ est obligatoire'}`)
                                                            .matches(
                                                                /^(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                                                                `${"Le mot de passe doit comporter 8 caractères avec l'utilisation d'un caractère spécial"}`
                                                            ),
                                                        passwordConfirmation: yup.string()
                                                            .oneOf([yup.ref('password'), null], 'Passwords must match')
                                                            .required(`${'Ce champ est obligatoire'}`),
                                                    })
                                                }
                                                onSubmit={async (
                                                    values 
                                                ) => {

                                                    setLoading(true);
                                                    await axiosInstance.post('/update_password.php', values)
                                                    .then(async function (response: any) {

                                                        console.log(response);

                                                        let user: any = window.localStorage.getItem( 'app_user' );

                                                        if (user !== null) {
                                                            user = JSON.parse(user);
                                                            
                                                            const new_data = {
                                                                ...user,
                                                                password_changed: response['data'].password_changed
                                                            };

                                                            dispatch( setUser( Admin.fromJson( new_data ) ) );

                                                            window.localStorage.setItem(
                                                                'app_user',
                                                                JSON.stringify(new_data)
                                                            );

                                                            confirmAlert({
                                                                overlayClassName: "alert-overlay",
                                                                customUI: ({ onClose }) => {
                                                                  return (
                                                                        <Alert 
                                                                            alert={{
                                                                                title: 'Succès',
                                                                                message: 'Le mot de passe a été changé avec succès, vous allez être déconnecté enfin de vous reconnecter avec le nouveau mot de passe.',
                                                                                actionText: 'Ok',
                                                                                action: () => {
                                                                                    onClose();
                                                                                }
                                                                            }}
                                                                        />
                                                                  );
                                                                }
                                                            });

                                                            if (formRef.current !== null) {
                                                                formRef.current.values.old_password = '';
                                                                formRef.current.values.password = '';
                                                                formRef.current.values.passwordConfirmation= '';
                                                            }

                                                            window.localStorage.removeItem("app_user");
                                                            dispatch( setUser( null ) );
                                                            window.location.href = "/auth";

                                                        } else {

                                                            confirmAlert({
                                                                overlayClassName: "alert-overlay",
                                                                customUI: ({ onClose }) => {
                                                                  return (
                                                                        <Alert 
                                                                            alert={{
                                                                                type: 'error',
                                                                                title: 'Erreur',
                                                                                message: 'Nous rencontrons une erreur innatendue, réessayez après.',
                                                                                actionText: 'Ok',
                                                                                action: () => {
                                                                                    onClose();
                                                                                }
                                                                            }}
                                                                        />
                                                                  );
                                                                }
                                                            });

                                                        }
                                                        
                                                        setLoading(false); 

                                                    })
                                                    .catch(function (error: any) {
                                                        console.log(error);
                                                        setLoading(false);
                                                        if (error.response.status === 404) {

                                                            confirmAlert({
                                                                overlayClassName: "alert-overlay",
                                                                customUI: ({ onClose }) => {
                                                                  return (
                                                                        <Alert 
                                                                            alert={{
                                                                                type: 'error',
                                                                                title: 'Erreur',
                                                                                message: "L'ancien mot de passe est incorrect.",
                                                                                actionText: 'Ok',
                                                                                action: () => {
                                                                                    onClose();
                                                                                }
                                                                            }}
                                                                        />
                                                                  );
                                                                }
                                                            });
                                                            

                                                        } else {

                                                            confirmAlert({
                                                                overlayClassName: "alert-overlay",
                                                                customUI: ({ onClose }) => {
                                                                  return (
                                                                        <Alert 
                                                                            alert={{
                                                                                type: 'error',
                                                                                title: 'Erreur',
                                                                                message: 'Nous rencontrons une erreur innatendue, réessayez après.',
                                                                                actionText: 'Ok',
                                                                                action: () => {
                                                                                    onClose();
                                                                                }
                                                                            }}
                                                                        />
                                                                  );
                                                                }
                                                            });

                                                        }
                                                       

                                                    });
                                                            
                                                            
                                                }}
                                                innerRef={formRef}
                                                >
                                                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                                <Form>
                                                                            <div className="form-body row"> 
                                                                                <div className='col-md-2'>
                                                                                    
                                                                                </div>

                                                                                <div className='col-md-8'> 
                                                                                    <div className='row'>  
                                                                                
                                                                                        <div className="form-group col-md-12"> 
                                                                                            <label className="text-dark" htmlFor="password">
                                                                                                {
                                                                                                    t('Ancien mot de passe')
                                                                                                }
                                                                                            </label>
                                                                                            <div className='input-group'>
                                                                                                <input  className={`form-control ${ 
                                                                                                    errors.old_password && 
                                                                                                touched.old_password ? "is-invalid":""}`}
                                                                                                    id="old_password"
                                                                                                    type={ oldPasswordInputType }
                                                                                                    name="old_password"
                                                                                                    placeholder={ `${t('Ancien mot de passe')}` }
                                                                                                    onChange={handleChange('old_password')}
                                                                                                    onBlur={handleBlur('old_password')}
                                                                                                    value={values.old_password}
                                                                                                />
                                                                                                <span className="input-group-append">
                                                                                                    <button onClick={event => {
                                                                                                        setOldPasswordInputType( oldPasswordInputType === 'password' ? 'text' : 'password' );
                                                                                                        setOldPasswordInputIcon( oldPasswordInputIcon === 'eye' ? "eye-slash" : "eye" );
                                                                                                    }} className="btn btn-outline-primary border-left-0 border" type="button">
                                                                                                            <i className={`fa fa-${oldPasswordInputIcon}`}></i>
                                                                                                    </button>
                                                                                                </span>

                                                                                                { 
                                                                                                errors.old_password &&
                                                                                                 touched.old_password && errors.old_password && 
                                                                                                <div id="validationServer05Feedback" 
                                                                                                className="invalid-feedback">
                                                                                                { errors.old_password &&
                                                                                                 touched.old_password && errors.old_password }
                                                                                                </div> 
                                                                                            }
                                                                                            </div>

                                                                                            

                                                                                        </div>


                                                                                        <div className="form-group col-md-12"> 
                                                                                            <label className="text-dark" htmlFor="password">
                                                                                                {
                                                                                                    t('Nouveau mot de passe')
                                                                                                }
                                                                                            </label>
                                                                                            <div className='input-group'>
                                                                                                <input  className={`form-control ${ 
                                                                                                    errors.password && 
                                                                                                touched.password ? "is-invalid":""}`}
                                                                                                    id="password"
                                                                                                    type={ newPasswordInputType }
                                                                                                    name="password"
                                                                                                    placeholder={ `${t('Nouveau mot de passe')}` }
                                                                                                    onChange={handleChange('password')}
                                                                                                    onBlur={handleBlur('password')}
                                                                                                    value={values.password}
                                                                                                />
                                                                                                <span className="input-group-append">
                                                                                                    <button onClick={event => {
                                                                                                        setNewPasswordInputType( newPasswordInputType === 'password' ? 'text' : 'password' );
                                                                                                        setNewPasswordInputIcon( newPasswordInputIcon === 'eye' ? "eye-slash" : "eye" );
                                                                                                    }} className="btn btn-outline-primary border-left-0 border" type="button">
                                                                                                            <i className={`fa fa-${ newPasswordInputIcon}`}></i>
                                                                                                    </button>
                                                                                                </span>

                                                                                                { errors.password && touched.password && errors.password && 
                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                    { errors.password && touched.password && errors.password }
                                    </div> }

                                                                                            </div>

                                                                                            

                                                                                        </div>


                                                                                        <div className="form-group col-md-12"> 
                                                                                            <label className="text-dark" htmlFor="password">
                                                                                                {
                                                                                                    t('Confirmation nouveau mot de passe')
                                                                                                }
                                                                                            </label>
                                                                                            <div className='input-group'>
                                                                                                <input  className={`form-control ${ 
                                                                                                    errors.passwordConfirmation && 
                                                                                                touched.passwordConfirmation ? "is-invalid":""}`}
                                                                                                    id="passwordConfirmation"
                                                                                                    type={ newPasswordConfirmInputType }
                                                                                                    name="passwordConfirmation"
                                                                                                    placeholder={ `${t('Confirmation nouveau mot de passe')}` }
                                                                                                    onChange={handleChange('passwordConfirmation')}
                                                                                                    onBlur={handleBlur('passwordConfirmation')}
                                                                                                    value={values.passwordConfirmation}
                                                                                                />
                                                                                                <span className="input-group-append">
                                                                                                    <button onClick={event => {
 
                                                                                                        setNewPasswordConfirmInputType( newPasswordConfirmInputType === 'password' ? 'text' : 'password'  );
                                                                                                        setNewPasswordConfirmInputIcon( newPasswordConfirmInputIcon === 'eye' ? "eye-slash" : "eye" );

                                                                                                    }} className="btn btn-outline-primary border-left-0 border" type="button">
                                                                                                            <i className={`fa fa-${ newPasswordConfirmInputIcon }`}></i>
                                                                                                    </button>
                                                                                                </span>
                                                                                                { errors.passwordConfirmation && touched.passwordConfirmation &&
                                                                                             errors.passwordConfirmation && 
                                                                                                <div id="validationServer05Feedback" className="invalid-feedback">
                                                                                                { errors.passwordConfirmation && touched.passwordConfirmation && errors.passwordConfirmation }
                                                                                                </div> }

                                                                                            </div>

                                                                                            
                                                                                            </div>
                                                                                    

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-actions">
                                                                                <div className="text-right">
                                                                                    <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                                        { t('Modifier') }
                                                                                        {
                                                                                            loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                                                <span className="sr-only">Loading...</span>
                                                                                            </div>
                                                                                        }
                                                                                    </button>
                                                                                    <button type="reset" className="btn btn-dark">
                                                                                        { t('Annuler') }
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                </Form>
                                                            )}
                                                        </Formik>

                                        </Tab>
                                        <Tab eventKey="profile" title="Profile">
                                        <>
                                        <br/>
                                        <p className='text-center'>Faites les modifications désirées photo ou informations personnelles puis valider le formaulaire pour appliquer les changements.</p>
                                        <br />
                                        { !loadMember && 
                                        <Formik
                                            initialValues={
                                                member != null  && member != undefined ? 
                                                {
                                                    ...member
                                                }:
                                                {
                                                    status: null,
                                                    firstname: '',
                                                    lastname: '',
                                                    email: '',
                                                    phone_number: '',
                                                    birth_date:  '',
                                                    country: '',
                                                    residence_country: '',
                                                    nationality: '',
                                                    adress: '',
                                                    profession: '',
                                                    photo: '',
                                                    created_at: '',
                                                    updated_at: '',
                                                    centerOfInterest: null,
                                            }}
                                            validationSchema={
                                                yup.object().shape({
                                                    status: yup
                                                        .string(),
                                                    firstname: yup
                                                        .string() 
                                                        .required(`${ t('Ce champ est obligatoire')}`),
                                                    lastname: yup
                                                        .string() 
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    email: yup
                                                        .string() 
                                                        .email('Email incorrect')
                                                        .required('Ce champ est obligatoire')
                                                        .test('checkEmailUnique', "Cet email a été déjà utilisé", value =>
                                                            parameterService.verifyEmail(value ?? '')
                                                            .then((res) => { 
                                                                console.log(res);
                                                                return  res.data.email ? res.data.email === member?.email ? true : false : true;
                                                            })
                                                            .catch((e) => {
                                                                console.log(e);
                                                                return false;
                                                            })
                                                        ),
                                                    phone_number: yup
                                                        .string() 
                                                        .required('Ce champ est obligatoire') 
                                                        .test('checkPhoneNumberUnique', "Ce numéro de téléphone a été déjà utilisé", value =>
                                                            parameterService.verifyPhoneNumber(value ?? '')
                                                            .then((res) => {
                                                                return res.data.phone_number ? res.data.phone_number === member?.phone_number ? true : false : true;
                                                            })
                                                            .catch((e) => {
                                                                console.log(e);
                                                                return false;
                                                            })
                                                        ),
                                                    birth_date:  yup
                                                        .date() 
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    country: yup
                                                        .string() 
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    residence_country: yup
                                                        .string() 
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    nationality: yup
                                                        .string() 
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    adress: yup
                                                        .string() 
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    profession: yup
                                                        .string() 
                                                        .required(`${t('Ce champ est obligatoire')}`),
                                                    photo: yup
                                                        .string(),
                                                    centerOfInterest: yup
                                                        .mixed<string>()
                                                        .required('Ce champ est obligatoire'),
                                                })
                                            }
                                            onSubmit={ async(values) => {

                                                        console.log(values);
                                                        setLoading(true);

                                                        if (image != null && image != '' && 
                                                        image != GLOBAL.api_media_url + "/membres/" + member?.photo ) {
                                                            values.photo = image;
                                                        }

                                                            parameterService.putUser( values ).then(async function (response: any) {
                                                                console.log(response);
                                                                
                                                                let user: any = window.localStorage.getItem( 'app_user' );

                                                                if (user !== null) {
                                                                    user = JSON.parse(user);
                                                                    
                                                                    const new_data = {
                                                                        ...user,
                                                                        photo: response['data'].user.photo,
                                                                        photo_changed: response['data'].photo_changed
                                                                    };
        
                                                                    dispatch( setUser( Admin.fromJson( new_data ) ) );
        
                                                                    window.localStorage.setItem(
                                                                        'app_user',
                                                                        JSON.stringify(new_data)
                                                                    );
        
                                                                    confirmAlert({
                                                                        overlayClassName: "alert-overlay",
                                                                        customUI: ({ onClose }) => {
                                                                          return (
                                                                                <Alert 
                                                                                    alert={{
                                                                                        title: 'Succès',
                                                                                        message: 'Votre profil a été mise à jour avec succès',
                                                                                        actionText: 'Ok',
                                                                                        action: () => {
                                                                                            onClose();
                                                                                        }
                                                                                    }}
                                                                                />
                                                                          );
                                                                        }
                                                                    });
        
                                                                  
        
                                                                } else {
        
                                                                    confirmAlert({
                                                                        overlayClassName: "alert-overlay",
                                                                        customUI: ({ onClose }) => {
                                                                          return (
                                                                                <Alert 
                                                                                    alert={{
                                                                                        type: 'error',
                                                                                        title: 'Erreur',
                                                                                        message: 'Nous rencontrons une erreur innatendue, réessayez après.',
                                                                                        actionText: 'Ok',
                                                                                        action: () => {
                                                                                            onClose();
                                                                                        }
                                                                                    }}
                                                                                />
                                                                          );
                                                                        }
                                                                    });
        
                                                                }

                                                                setLoading(false); 
                                                            })
                                                            .catch(function (error: any) {
                                                                console.log(error);

                                                                confirmAlert({
                                                                    overlayClassName: "alert-overlay",
                                                                    customUI: ({ onClose }) => {
                                                                      return (
                                                                            <Alert 
                                                                                alert={{
                                                                                    type: 'error',
                                                                                    title: 'Erreur',
                                                                                    message: 'Nous rencontrons une erreur innatendue, réessayez après.',
                                                                                    actionText: 'Ok',
                                                                                    action: () => {
                                                                                        onClose();
                                                                                    }
                                                                                }}
                                                                            />
                                                                      );
                                                                    }
                                                                });

                                                                setLoading(false); 
                                                            });
                                                       
                                                        
                                            }}
                                            // innerRef={formRef}
                                                    >
                                                    {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                                                            <Form>
                                                                        <div className="form-body row"> 
                                                                            <div className='col-md-4'>
                                                                                <div style={{ margin: "0 auto",
                                                                                borderRadius: "50%",    }} >
                                                                                <img style={{ width:"200px", height:"200px", margin: "0 auto", borderRadius: "50%" }} 
                                                                                src={ image != null && image != "" ? image : "assets/images/user.png" }alt="" />

                                                                                <div style={{ width:"50px", height:"50px", position: "absolute", right: "45%", 
                                                                                bottom: "70%", borderRadius: "50%",  backgroundColor: "#edd663", cursor: "pointer"  }}>
                                                                                        <label className='fas fa-image fa-2x' style={{ color: "white", marginTop: "8px", 
                                                                                        marginLeft: "8px", cursor: "pointer"  }}
                                                                                         htmlFor="photoProfil" ></label>
                                                                                        <input type="file" 
                                                                                                className={`form-control custom-file-input `} 
                                                                                                    id="photoProfil" 
                                                                                                    onChange={changeListener} 
                                                                                                    placeholder="Photo" />
                                                                                </div>
                                                                                
                                                                                </div>
                                                                            </div>

                                                                            <div className='col-md-8'> 
                                                                                <div className='row'> 

                                                                                <div className="form-group col-md-12">
                                                                        {/* <label htmlFor="">
                                                                            Statut du membre: <b>{ 
                                                                            getMemberStatus(values.status) }</b> </label>  */}
                                                                            <br/> 
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <label htmlFor="">Prénom</label>

                                                                        <input  className={`form-control ${ errors.firstname && touched.firstname ? "is-invalid":""}`}
                                                                                                            id="firstname" 
                                                                                                            name="firstname"
                                                                                                            onChange={(event: any) => { 
                                                                                                                handleChange('firstname')(event);
                                                                                                                // getProductByBarcode();
                                                                                                            }}
                                                                                                            onBlur={handleBlur('firstname')}
                                                                                                            value={values.firstname == null ? ''  :
                                                                                                            values.firstname }
                                                                                                            placeholder="Prénom" />
                                                                                                        { errors.firstname && 
                                                                                                          touched.firstname && 
                                                                                                    <div id="validationServer05Feedback" 
                                                                                                        className="invalid-feedback">
                                                                                                            { errors.firstname.toString()  }
                                                                                                    </div> }
                                                                        
                                                                </div>
                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="">Nom</label>
                                                                    <input  className={`form-control ${ errors.lastname && touched.lastname ? "is-invalid":""}`}
                                                                                                            id="lastname" 
                                                                                                            name="lastname"
                                                                                                            onChange={(event: any) => { 
                                                                                                                handleChange('lastname')(event);
                                                                                                                // getProductByBarcode();
                                                                                                            }}
                                                                                                            onBlur={handleBlur('lastname')}
                                                                                                            value={values.lastname == null ? ''  :
                                                                                                            values.lastname }
                                                                                                            placeholder="Nom" />
                                                                                                        { errors.lastname && touched.lastname &&
                                                                                                        errors.lastname && 
                                                                                                    <div id="validationServer05Feedback" 
                                                                                                    className="invalid-feedback">
                                                                                                            { errors.lastname.toString()  }
                                                                                                    </div> }
                                                                </div>

                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="">Pays d'origine</label>
                                                                    {   countriesLoading ? <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                                        </div> : <select className={`form-control custom-select ${ errors.country &&
                                                                                                        touched.country ? "is-invalid" : "" }`}
                                                                                                    id="country" 
                                                                                                    name="country"
                                                                                                    onChange={event => { 
                                                                                                        handleChange('country')(event); 
                                                                                                    }}
                                                                                                    onBlur={handleBlur('country')}
                                                                                                    value={values.country == null ? '' : values.country}
                                                                                                    placeholder="Pays d'origine" >
                                                                        <option value="">Pays d'origine</option>

                                                                        {
                                                                            countries.map((country: any, index: number) => <option key={index} value={ country.nicename }>{ country?.nicename }</option>)
                                                                        }
                                                                    </select> }
                                                                </div>

                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="">Pays de résidence</label>
                                                                    {  countriesLoading  ? <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                                        </div> : <select className={`form-control custom-select ${ errors.residence_country &&
                                                                                                        touched.residence_country ? "is-invalid" : "" }`}
                                                                                                    id="residence_country" 
                                                                                                    name="residence_country"
                                                                                                    onChange={event => { 
                                                                                                        handleChange('residence_country')(event); 
                                                                                                        
                                                                                                        if (event.target.value !== '') {
                                                                                                            const ctry: Array<any> =  countries.filter((crt: any) =>  crt.nicename === event.target.value);
                                                                                                            if (ctry.length > 0) setCountryPhoneCode(ctry[0].phonecode);
                                                                                                            else setCountryPhoneCode('');
                                                                                                        }
                                                                                                    }}
                                                                                                    onBlur={handleBlur('residence_country')}
                                                                                                    value={values.residence_country == null ? '' : values.residence_country}
                                                                                                    placeholder="Pays de résidence" >
                                                                        <option value="">Pays de résidence</option>
                                                                        {
                                                                            countries.map((country: any, index: number) => <option key={index} value={ country.nicename }>{ country?.nicename }</option>)
                                                                        }
                                                                        {/* <option value="Togo">Togo</option>
                                                                        <option value="Bénin">Bénin</option>
                                                                        <option value="Ghana">Ghana</option>
                                                                        <option value="Burkina-Faso">Burkina-Faso</option> */}
                                                                    </select> }
                                                                </div>


                                                                


                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="">Nationalité</label>
                                                                    <input  className={`form-control ${ errors.nationality && touched.nationality ? "is-invalid":""}`}
                                                                                                            id="nationality" 
                                                                                                            name="nationality"
                                                                                                            onChange={(event: any) => { 
                                                                                                                handleChange('nationality')(event); 
                                                                                                            }}
                                                                                                            onBlur={handleBlur('nationality')}
                                                                                                            value={values.nationality == null ? ''  :
                                                                                                            values.nationality }
                                                                                                            placeholder="Nationalité" />
                                                                                                        { errors.nationality && touched.nationality &&
                                                                                                        errors.nationality && 
                                                                                                    <div id="validationServer05Feedback" 
                                                                                                    className="invalid-feedback">
                                                                                                            { errors.nationality.toString()  }
                                                                                                    </div> }
                                                                </div>

                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="">Date de naissance</label>
                                                                    <input type="date" className={`form-control ${ errors.birth_date && touched.birth_date ? "is-invalid":""}`}
                                                                                                            id="birth_date" 
                                                                                                            name="birth_date"
                                                                                                            onChange={(event: any) => { 
                                                                                                                handleChange('birth_date')(event); 
                                                                                                            }}
                                                                                                            onBlur={handleBlur('birth_date')}
                                                                                                            value={values.birth_date == null ? ''  :
                                                                                                            values.birth_date }
                                                                                                            placeholder="Date de naissance" />
                                                                                                        { errors.birth_date && touched.birth_date &&
                                                                                                        errors.birth_date && 
                                                                                                    <div id="validationServer05Feedback" 
                                                                                                        className="invalid-feedback">
                                                                                                            { errors.birth_date.toString()  }
                                                                                                    </div> }
                                                                </div>

                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="">Numéro de téléphone</label>
                                                                    <div className="input-group mb-2" >
                                                                        {/* <div className="input-group-prepend">
                                                                            <div className="input-group-text" >{ countryPhoneCode }</div>
                                                                        </div> */}

                                                                        <input style={{ marginLeft: 10 }} type='tel'  className={`form-control ${ errors.phone_number && touched.phone_number ? "is-invalid":""}`}
                                                                                                            id="phone_number" 
                                                                                                            name="phone_number"
                                                                                                            onChange={(event: any) => { 
                                                                                                                handleChange('phone_number')(event); 
                                                                                                            }}
                                                                                                            onBlur={handleBlur('phone_number')}
                                                                                                            value={values.phone_number == null ? ''  :
                                                                                                            values.phone_number }
                                                                                                            placeholder="Numéro de téléphone" />
                                                                                                        { errors.phone_number && touched.phone_number &&
                                                                                                        errors.phone_number && 
                                                                                                    <div id="validationServer05Feedback" 
                                                                                                    className="invalid-feedback">
                                                                                                            { errors.phone_number.toString()  }
                                                                                                    </div> }
                                                                    </div>
                                                                </div>


                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="">Email</label>
                                                                    <input disabled={true} className={`form-control ${ errors.email && touched.email ? "is-invalid":""}`}
                                                                                                            id="email" 
                                                                                                            name="email"
                                                                                                            onChange={(event: any) => { 
                                                                                                                handleChange('email')(event); 
                                                                                                            }}
                                                                                                            onBlur={handleBlur('email')}
                                                                                                            value={values.email == null ? ''  :
                                                                                                            values.email }
                                                                                                            placeholder="Email" />
                                                                                                        { errors.email && touched.email &&
                                                                                                        errors.email && 
                                                                                                    <div id="validationServer05Feedback" 
                                                                                                    className="invalid-feedback">
                                                                                                            { errors.email.toString() }
                                                                                                    </div> }
                                                                </div>
                                    
                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="">Adresse</label>
                                                                    <input  className={`form-control ${ errors.adress && touched.adress ? "is-invalid":""}`}
                                                                                                            id="adress" 
                                                                                                            name="adress"
                                                                                                            onChange={(event: any) => { 
                                                                                                                handleChange('adress')(event); 
                                                                                                            }}
                                                                                                            onBlur={handleBlur('adress')}
                                                                                                            value={values.adress == null ? ''  :
                                                                                                            values.adress }
                                                                                                            placeholder="Adresse" />
                                                                                                        { errors.adress && touched.adress &&
                                                                                                        errors.adress && 
                                                                                                    <div id="validationServer05Feedback" 
                                                                                                        className="invalid-feedback">
                                                                                                            { errors.adress.toString() }
                                                                                                    </div> }
                                                                </div>
                                    

                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="">Profession</label>
                                                                    <input className={`form-control ${ errors.profession && touched.profession ? "is-invalid":""}`}
                                                                                                            id="profession" 
                                                                                                            name="profession"
                                                                                                            onChange={(event: any) => { 
                                                                                                                handleChange('profession')(event);
                                                                                                                // getProductByBarcode();
                                                                                                            }}
                                                                                                            onBlur={handleBlur('profession')}
                                                                                                            value={values.profession == null ? ''  :
                                                                                                            values.profession }
                                                                                                            placeholder="Profession" />
                                                                                                        { errors.profession && touched.profession &&
                                                                                                        errors.profession && 
                                                                                                    <div id="validationServer05Feedback" 
                                                                                                        className="invalid-feedback">
                                                                                                            { errors.profession.toString()  }
                                                                                                    </div> }
                                                                </div>

                                                                <div className="form-group col-md-12">
                                                                    <label htmlFor="">Quel sont vos centres d'intérêt ? </label> <br/>

                                                                    <div style={{ marginLeft: "10px" }} role="group" aria-labelledby="checkbox-group">
                                                                        <label>
                                                                            <Field type="checkbox" name="centerOfInterest" value="Développement Personnel" />
                                                                            Développement Personnel
                                                                        </label>
                                                                        <br/>
                                                                        <label>
                                                                            <Field type="checkbox" name="centerOfInterest" value="Formation sur l'Épargne et la Tontine" />
                                                                            Formation sur l'Épargne et la Tontine
                                                                        </label>
                                                                        <br/>
                                                                        <label>
                                                                            <Field type="checkbox" name="centerOfInterest" value="Initiation aux marchés des valeurs obligataires et participatives (Actions/Obligations)" />
                                                                            Initiation aux marchés des valeurs obligataires et participatives (Actions/Obligations)
                                                                        </label>
                                                                        <br/>
                                                                        <label>
                                                                            <Field type="checkbox" name="centerOfInterest" value="Coaching Immobilier" />
                                                                            Coaching Immobilier
                                                                        </label>
                                                                        <br/>
                                                                        <label>
                                                                            <Field type="checkbox" name="centerOfInterest" value="Accompagnement financier de StartUps" />
                                                                            Accompagnement financier de StartUps
                                                                        </label>
                                                                    </div>

                                                                </div>







                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-actions">
                                                                            <div className="text-right">
                                                                                <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                                                    { t('Valider') }
                                                                                    {
                                                                                        loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                                                            <span className="sr-only">Loading...</span>
                                                                                        </div>
                                                                                    }
                                                                                </button>
                                                                                <button type="reset" className="btn btn-dark">
                                                                                    { t('Annuler') }
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                            </Form>
                                                        )}
                                                    </Formik> }
                                                    </>
                                        </Tab>
                                        
                                    </Tabs>
                                    
                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>  
    );
}

export default Parameter